import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import EventModal from "../ModalVideo/EventModal";

const Diversity = () => {
  const IndianEvents = [
    {
      eventImg: "assets/Reviews/KidsReview.png",
      eventVideo: "https://www.youtube.com/embed/XUh_Dj8_HzY",
    },
    {
      eventImg: "assets/Reviews/KidsReview2.png",
      eventVideo: "https://www.youtube.com/embed/6U-Fo1riLC0?si=AUFbPeYDwGr7uWzI",
    },
    {
        eventImg: "assets/Reviews/KidsReview3.png",
      eventVideo: "https://www.youtube.com/embed/rV7qfrLL61M?si=6S6dkhZyfId_4iZ1",
    },
  ];


  return (
    <div className="">
      <div
        className=" pt-10 pb-10 bg-blue" style={{
            WebkitMask: `
              radial-gradient(34.99px at 50% 48px, #000 99%, #0000 101%) calc(50% - 60px) 0/120px 100%,
              radial-gradient(34.99px at 50% -18px, #0000 99%, #000 101%) 50% 30px/120px 100% repeat-x
            `,
            mask: `
              radial-gradient(34.99px at 50% 48px, #000 99%, #0000 101%) calc(50% - 60px) 0/120px 100%,
              radial-gradient(34.99px at 50% -18px, #0000 99%, #000 101%) 50% 30px/120px 100% repeat-x
            `,
        }}
      >
        <div className="container mx-auto grid grid-cols-12 gap-6">
          <div className=" z-10 mb-3 p-10 rounded-xl rounded-l-[40px] col-span-full text-center">
            <h3 className="text-2xl text-black font-extrabold md:text-4xl lg:text-5xl">
             Diversity of students
            </h3>
          </div>
          <div className="col-span-12">
          <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 xs:grid-cols-1 gap-6 mb-7 justify-center">
          <img src="/assets/Flags/india.png" className="w-32"/>
           <img src= "/assets/Flags/US.png" className="w-32" />
           <img src= "/assets/Flags/canada.png" className="w-32" />
           <img src= "/assets/Flags/germany.png" className="w-32" />
           <img src="/assets/Flags/australia.png" className="w-32" />
              </div>
              </div>
         
        </div>
      </div>
    </div>
  );
};

export default Diversity;
