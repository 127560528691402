import React from "react";
import BookForm from "./BookForm";

const BookClass = () =>{

    return(
      <div className="grid mx-auto py-8 gap-6  container rounded-lg pt-18">
    
        <div className=" bg-[#9ad2a8] grid grid-cols-12  p-10 rounded-3xl  md:gap-6">
              {/* Left Section */}
        <div className="col-span-12 lg:col-span-7 p-5 ] md:pr-32  mt-10 ">
          <h3 className="md:mb-5 text-2xl font-extrabold  md:text-4xl lg:text-5xl">
            <span className="bg-clip-text text-white lg:leading-[62px]">Lorem Ipsum Dummy Text Data</span>
          </h3>
          <p className=" pt-10 text-2xl leading-[30px] text-white">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
            </p>
        </div>
        
        {/* Right Section */}
        <div className="col-span-12 lg:col-span-5 gap-6">
          <BookForm />
        </div>
        </div>
      </div>
    
    )
}
export default BookClass ;