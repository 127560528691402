import React from "react";
import PageTitle from "../Header/PageTitle";

function Terms() {
  return (
    <div>
      <PageTitle title="Term & Condition" activeTitle="Term & Condition" />
      <div className="container mx-auto px-6 lg:px-16 lg:mt-20 mt-5">
        <div className="mt-6">
          <h3 className="text-lg font-semibold text-gray-800">Electronic Record and Legal Compliance</h3>
          <p className="text-gray-600 text-md leading-relaxed font-medium text-justify mt-2">
        In accordance with the Information Technology Act, 2000, and the applicable rules therein, this document is an electronic record. It has been generated by a computer system and therefore does not require physical or digital signatures. This document complies with Rule 3(1) of the Information Technology (Intermediaries Guidelines) Rules, 2011, which mandates the publication of a website's terms and conditions, privacy statement, and user agreement. For reference, the website in question is <span className="font-semibold text-gray-800"><a href="https://bacchapanti.com/" className="text-blue-600 hover:underline">www.bacchapanti.com</a></span>.
          </p>
        </div>

        <div className="mt-6">
          <h3 className="text-lg font-semibold text-gray-800">Eligibility and Registration Requirements</h3>
          <p className="text-gray-600 text-md leading-relaxed font-medium text-justify mt-2">
          The website is open for use by individuals, businesses, partnerships, and other legal entities. However, in line with the Indian Contract Act of 1872, registration on the website can only be completed by individuals who are legally competent to enter into binding contracts.Users who are categorized as "incompetent to contract" under the Indian Contract Act, 1872 — such as minors or insolvents — are prohibited from using the website unless they are under the legal or constructive guardianship of someone capable of entering into contracts on their behalf.
          </p>
          <p className="text-gray-600 text-md leading-relaxed font-medium text-justify mt-2">
          By registering for an account on the website, you confirm that the information you provide is accurate and that you are legally capable of entering into this agreement. You also assert that you have the authority to bind yourself or your business entity to these terms. Furthermore, you agree to use the website in compliance with the End User License Agreement (EULA).Should we find that you are using the website in violation of the EULA, Bacchapanti reserves the right to suspend or terminate your registration and deny access to the website at its sole discretion.
          </p>
        </div>

        <div className="mt-6">
          <h3 className="text-lg font-semibold text-gray-800">Electronic Communication and Consent</h3>
          <p className="text-gray-600 text-md leading-relaxed font-medium text-justify mt-2">
          By using the website and interacting with Bacchapanti, you acknowledge and consent to electronic communications. These may include emails, website announcements, or other forms of communication. By using our services, you agree to receive such communications from Bacchapanti.
          </p>
        </div>

        <div className="mt-6">
          <h3 className="text-lg font-semibold text-gray-800">Payments and Fees</h3>
          <p className="text-gray-600 text-md leading-relaxed font-medium text-justify mt-2">
          Bacchapanti will charge for certain services based on the pricing structure outlined on the website. By using the paid services, you agree to pay the corresponding fees in advance as specified on the site.You further confirm that you will not dispute or contest the fees for services, as you are voluntarily agreeing to them without any coercion. You also acknowledge that you have read and accepted the payment terms that are provided when you proceed to make a payment.Bacchapanti processes payments through third-party payment providers. In the event that an order is canceled or incorrectly processed due to payment issues, Bacchapanti is not responsible. 
          </p>
          <p className="text-gray-600 text-md leading-relaxed font-medium text-justify mt-2">
          While we take reasonable precautions when working with third-party payment systems, we cannot control their technology, processes, or workflows, and are therefore not liable for any errors made by these providers. Users have no authority to challenge or influence the fees charged by Bacchapanti, as they are determined solely by the company.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Terms;
