import React from "react";

const data = [
  {
    number: 1,
    title: "Book Your Free Demo",
    desc: 'Click "Book Free Demo Now" and get started in no time.',
  },

  {
    number: 2,
    title: "Experience Bachhapanti Magic",
    desc: "Enjoy a fun, interactive session with  our amazing teachers and get a taste of Bachhapanti's unique style.",
  },

  {
    number: 3,
    title: "You're all set!",
    desc: "Our academic counselor will assist you with batch assignment, and the learning adventure begins",
  },
];
const JoinBachapanti = () => {
  return (
    <div className=" mx-auto container ">
      <h3 className=" text-2xl font-extrabold  md:text-4xl lg:text-5xl mt-10 text-center mb-8">
        Join Bachhapanti in 3 Easy Steps!
      </h3>
      <div className="grid grid-cols-12 gap-6">
        {data.map((item, index) => (
          <div
            key={index}
            className="col-span-12 md:col-span-4 text-center relative p-2"
          >
            <div className="w-24 h-24 flex items-center justify-center border-4 border-[#9ad2a8] text-[#9ad2a8] rounded-full mx-auto mb-4 text-6xl font-bold">
              {item.number}
            </div>
            <div className="relative bg-white rounded-lg ml-5 mr-5 pt-5 pb-10 pl-5 pr-5 z-10 min-h-[220px]">
              <h2 className="text-2xl font-bold h-24 flex items-top justify-center">
                {item.title}
              </h2>
              <p className="text-gray-600">{item.desc}</p>
            </div>
            <div class="relative bg-[#9ad2a8] h-[50px] w-full top-[-25px] rounded-xl">
              <div class="absolute top-full left-0 right-0 mx-auto w-0 h-0 border-t-[20px] border-t-[#9ad2a8] border-l-[15px] border-l-transparent border-r-[15px] border-r-transparent"></div>
            </div>
          </div>
        ))}
      </div>

      <div className="grid grid-cols-12 gap-6 bg-[#9ad2a8] p-10 rounded-[30px] pt-18 text-center">

        <h5 className="col-span-12 leading-tight pt-6 pb-6 md:pl-40 md:pr-40 text-white text-4xl font-bold"> Join Bacchapanti Today And Make  Math A Fun, Exciting Journey!</h5>
      </div>
    </div>
  );
};

export default JoinBachapanti;
