import React from 'react';
import { useLocation } from 'react-router-dom';
import SecondFooter from './SecondFooter';
import MainFooter from './MainFooter';
import WhatsAppButton from '../Whastapp/WhatsButton';

function Footer() {
    const location = useLocation();

    return (
        <>
            {location.pathname === "/layout" ? <SecondFooter /> : <MainFooter />}
            
            {/* WhatsApp  Button */}
            <WhatsAppButton phoneNumber="+91 9354967137" message="Hi! I'm interested in Bacchapanti's courses for kids' development."  />
        </>
    );
}

export default Footer;
