import React from "react";
import PageTitle from "../Header/PageTitle";

function Privacy() {
  return (
    <div>
      <PageTitle title="Privacy" activeTitle="Privacy Policy" />
      <div className="container mx-auto px-6 lg:px-16  lg:mt-20 mt-5">
        <p className="text-gray-600 text-md leading-relaxed font-medium text-justify">
          At <span className="font-semibold text-gray-800"><a href="https://bacchapanti.com/" className="text-blue-600 hover:underline">Bacchapanti</a></span>, your privacy is our priority. This Privacy Policy document outlines the types of personal information that are received and collected by Bacchapanti.com and how they are used.
        </p>

        <div className="mt-6">
          <h3 className="text-lg font-semibold text-gray-800">Personal Information</h3>
          <p className="text-gray-600 text-md leading-relaxed font-medium text-justify mt-2">
            When you register on <a href="https://bacchapanti.com/" className="text-blue-600 hover:underline">Bacchapanti.com</a>, subscribe to our newsletter, or interact with the website in other ways, we may ask for your personal information, such as your name, email address, phone number, and other contact details.
          </p>
        </div>

        <div className="mt-6">
          <h3 className="text-lg font-semibold text-gray-800">Log Data</h3>
          <p className="text-gray-600 text-md leading-relaxed font-medium text-justify mt-2">
            Like many websites, we collect information that your browser sends whenever you visit our website. This log data may include details such as your computer's IP address, browser type, browser version, the pages of our website that you visit, the time and date of your visit, and other statistics.
          </p>
        </div>

        <div className="mt-6">
          <h3 className="text-lg font-semibold text-gray-800">Cookies</h3>
          <p className="text-gray-600 text-md leading-relaxed font-medium text-justify mt-2">
            We use cookies to improve your experience on our website. Cookies are small text files placed on your device to enhance user experience. You can manage or disable cookies in your browser settings.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
