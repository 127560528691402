import React, { useEffect, useState } from 'react';
import { faAngleLeft, faBars, faChevronDown, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const navLinks = [
   
    { label: "Contact Us", link: '/contact' },
];

const SecondHeader = () => {
    const [mobileMenu, setMobileMenu] = useState(false);

    return (
        <>   
        <nav
            className='text-xl font-bold bg-white text-white fixed w-full z-[999] py-1' >

            <div className='container relative'>
                    <div className='flex justify-between text-center items-center'>
                        <Link to="/" className='cursor-pointer'>
                            <img src="assets/Img/BacchapantiLogo.png" className="h-28 rounded-lg" alt="logo" />
                        </Link>
                        <div className="lg:hidden md:hidden cursor-pointer sm:inline-block w-14 h-14 text-center rounded-full bg-[#ffc85b] hover:bg-[#45b3df] text-white p-2 transition duration-500">
                            <div className='border-2 border-dashed border-white rounded-full w-10 h-10 p-3 text-xs font-bold'>
                                {
                                    mobileMenu ? <FontAwesomeIcon icon={faXmark} size="lg" style={{ color: "#FFFFFF", }} onClick={() => setMobileMenu(false)} />
                                        : <FontAwesomeIcon icon={faBars} size="lg" style={{ color: "#FFFFFF", }} onClick={() => setMobileMenu(true)} />
                                }
                            </div>
                        </div>


                        <div className="hidden justify-center lg:flex md:flex">
                            <ul className="list-reset flex items-center">
                                {navLinks.map((item) => (
                                    <li className="mr-10 bg-[#f17105] p-3 pl-5 pr-5 rounded-xl">
                                        <Link to={item.link}
                                            className="block px-1 text-lg lg:text-sm  md:text-sm transition-all hover:text-primary-color">{item.label}</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
               

                {/* Mobile Menu */}
                <div className={` fixed inset-x-0 z-50 pt-20 opacity-0 transition-all ${mobileMenu ? 'opacity-100 pointer-events-auto contents lg:hidden' : 'hidden'}`}>
                    <div className="absolute right-2 z-40 w-[50%] px-6 top-[103px] shadow-sm sm:w-1/2 bg-[#ffc85b]">
                        {/* Mobile menu items */}
                        <div className='flex flex-col cursor-pointer'>
                            {navLinks.map((item) => (
                                <div className="">
                                    <Link to={item.link} onClick={() => {
                                        setMobileMenu(!mobileMenu);
                                    }}
                                        className="block w-full text-lg text-center cursor-pointer border-b border-grey-dark py-2 font-medium text-secondary">{item.label}</Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        
        </>
    );
};

export default SecondHeader;