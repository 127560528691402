import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Testimonials = [
  {
    image: "assets/Achievements/image-4.png",
    name: "Hastimal Saraswat",
    para: "My grand daughter advita saraswat has shown lots of improvement in her concentration and she actively participate in every competition which increase her self confidence. Thank you so much bachhapanti team for your dedication",
    parentInfo: "Advocate",
  },
  {
    image: "assets/Achievements/image-4.png",
    name: "John Doe",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. ",
    parentInfo: "Advocate",
  },
 
];

const Testimonial = () => {
  const slideConfig = {
    loop: true,
    animateOut: "fadeOut",
    margin: 10,
    nav: true,
    navSpeed: true,
    dots: false,
    autoplay: false,
    autoplayTimeout: 1000,
    autoplayHoverPause: true,
    smartSpeed: 1000,
    navText: [
      '<span class="owl-prev arrow prev">‹</span>',
      '<span class="owl-next arrow next">›</span>',
    ],
    responsive: {
      0: { items: 1 },
      576: { items: 1 },
      768: { items: 1 },
      1200: { items: 1 },
    },
  };

  return (
    <>
      <div className="grid grid-cols-12  gap-6 container">
        <div className="mt-5  mb-5 p-10 rounded-xl rounded-l-[40px] col-span-full text-center">
          <h5>
            <span className="text-[35px] font-seaweedScript font-medium text-[#f48506]">
              Testimonials
            </span>
          </h5>
          <h3 className=" text-2xl font-extrabold  md:text-4xl lg:text-5xl">
            Parents Reviews
          </h3>
          <p className="mt-5 text-xl text-gray-700 m-10 ">
            Real Stories of Success and Transformation
          </p>
        </div>
        <div className="col-span-12 gap-6 -mt-36">
          <OwlCarousel className="owl-theme" {...slideConfig}>
            {Testimonials.map((testimonial, index) => (
              <div
                className="relative flex justify-center items-center p-4"
                key={index}
              >
                <div className="grid grid-cols-12 gap-3 items-center pl-20 pr-20 w-full">
                  <div className=" col-span-12 lg:col-span-4 ">
                    <div className="relative w-80 h-96">
                      <div className="absolute top-12 left-0 w-40 h-40 bg-[#6610f2] rounded-full z-0"></div>
                      <div className="absolute -bottom-0  right-12 w-40 h-40 bg-white shadow-lg rounded-full z-0"></div>
                      <div className="absolute p-5 border-4 top-16 w-[300px] h-[300px] rounded-full z-10 border-[#6610f2]">
                        <img
                          src={testimonial.image}
                          alt="Profile"
                          className="absolute inset-0 top-[3px] w-[290px] h-[290px] bg-[#f48506] rounded-full object-cover border-4 border-white  z-10 "
                        />
                      </div>
                    </div>
                  </div>

                  {/* Description Section */}
                  <div className="col-span-12 lg:col-span-8">
                    <div className="bg-white p-6   relative">
                        <div className="relative" >
                        <img src ="/assets/ColumnImg/quotes.png"  className="w-2 lg:w-5 z-10 lg:absolute lg:-top-12 lg:-left-7" style ={{
                            width:"80px"
                        }}/>
                      <p className="text-black mb-2 md:text-base text-sm lg:absolute z-20">
                        {testimonial.para}
                      </p>
                      </div>
                      <h4 className="text-gray-800 font-bold text-lg md:text-xl lg:mt-32">
                        -{testimonial.name}
                      </h4>
                      <p className=" text-[15px] italic text-gray-500">
                        {testimonial.parentInfo}
                      </p>
                      
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
