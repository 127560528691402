import React from 'react'
import PageTitle from '../Header/PageTitle'

function Refund() {
  return (
    <div>
      <PageTitle title="Refund" activeTitle="Refund Policy" />
      <div className='container relative lg:mt-20 mt-5'>
        <p className='text-[#616262] text-md text-center leading-relaxed font-medium '>At <span className="font-semibold text-gray-800"><a href="https://bacchapanti.com/" className="text-blue-600 hover:underline">Bacchapanti</a></span>, we are committed to ensuring our clients have a positive experience with us. Refunds are considered only in cases of erroneous payments, and this decision is made at the sole discretion of the company's Directors. Our goal is to maintain client satisfaction at all times.</p>
<p className='text-[#616262] text-md text-center mt-4 leading-relaxed font-medium '>
Please note that once you sign up for any of our packages, we are unable to process a refund under any circumstances. However, rest assured that we will support you throughout your journey with us, offering guidance and fulfilling our counseling commitments to ensure you receive the best possible experience. </p>
<div className="mt-5 text-center">
          <p className="text-gray-700 text-lg font-medium">Best regards,</p>
          <p className="text-lg font-semibold text-gray-800">Bacchapanti</p>
        </div>
      </div>
    </div>
  )
}

export default Refund